import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Image from '../../uielements/Image'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const SlickWrapper = styled.div`
    picture {
        width: 100%;
        display: block;

        img {
            width: 100%;
            display: block;
        }
    }
`

const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    rows: 1,
    slidesPerRow: 1,
}

const MySlickSlider = ({ props, data }) => {
    const { i18n } = useTranslation()
    return (
        <SlickWrapper>
            <Slider {...settings}>
                {data.slideshow.nodes.map(
                    slide =>
                        i18n.language === slide.node_locale && (
                            <Link to={slide.link} title="slide">
                                <Image obj={slide.featuredImage} />
                            </Link>
                        )
                )}
            </Slider>
        </SlickWrapper>
    )
}

const Slideshow = props => {
    return (
        <StaticQuery
            query={graphql`
                query fetchSlideshow {
                    slideshow: allContentfulSlideshow(
                        filter: { tag: { title: { eq: "home_slider" } } }
                    ) {
                        nodes {
                            id
                            node_locale
                            title
                            featuredImage {
                                url
                                title
                                gatsbyImageData(
                                    quality: 75
                                    jpegProgressive: true
                                    sizes: "1920, 960, 480"
                                    cropFocus: CENTER
                                )
                            }
                            link
                        }
                    }
                }
            `}
            render={data2 => <MySlickSlider data={data2} props={props} />}
        ></StaticQuery>
    )
}

export default Slideshow
