import React, { useEffect } from 'react'
import Layout from '../components/layout'
import AdultsGrid from '../components/sections/AdultsGrid'
import ChildrenGrid from '../components/sections/ChildrenGrid'
import Spacer from '../components/uielements/Spacer'
import { useTranslation } from 'react-i18next'
import Button from '../components/uielements/Button'
import Parenting from '../components/sections/Parenting'
import FlexWrapper from '../components/uielements/flexWrapper'
import BlogSlider from '../components/buildingBlocks/BlogSlider'
import AboutMe from '../components/sections/AboutMe'
import Slideshow from '../components/buildingBlocks/Slideshow'
import Wrapper from '../components/Wrapper'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import Seo from '../components/seo'
import { Widget } from '@typeform/embed-react'
import InnerTitle from '../components/uielements/InnerTitle'

const SlideshowWrapper = styled.div`
    background: ${({ theme }) => theme.blue};
    overflow-y: hidden;

    .slick-prev {
        left: 30px;
        z-index: 9;
        width: 50px;
        height: 50px;
    }

    .slick-next {
        right: 30px;
        z-index: 9;
        width: 50px;
        height: 50px;
    }

    .slick-prev:before,
    .slick-next:before {
        font-size: 50px;
    }

    .slick-slider {
        margin: 30px auto;
    }
`

const IndexPage = () => {
    const { t, i18n } = useTranslation()
    const currentLang = i18n.language
    let language = currentLang

    useEffect(() => {
        const lang =
            new URLSearchParams(window.location.search).get('lang') ?? 'el-GR'
        language =
            currentLang !== lang ? i18n.changeLanguage(lang) : currentLang
    }, [])

    return (
        <Layout locale={language}>
            <Helmet>
                <meta property="og:title" content={t('main-seo-title')} />
            </Helmet>
            <Seo title={t('main-seo-title')} />
            <SlideshowWrapper>
                <Wrapper>
                    <Slideshow />
                </Wrapper>
            </SlideshowWrapper>

            <Wrapper>
                <Spacer top="8" bottom="8" />
                <ChildrenGrid />
                <Spacer top="8" bottom="8" />
                <FlexWrapper justifyContent="center" alignItems="center">
                    <Button link="/children" arrow="blue">
                        {' '}
                        {t('more-on-children')}{' '}
                    </Button>
                </FlexWrapper>
                <Spacer top="8" bottom="8" />
                <Parenting />
                <Spacer top="8" bottom="8" />
                <AdultsGrid displayInHome={true} />
                <Spacer top="8" bottom="8" />
                <FlexWrapper justifyContent="center" alignItems="center">
                    <Button link="/adults" arrow="blue">
                        {' '}
                        {t('more-on-adults')}{' '}
                    </Button>
                </FlexWrapper>
                <Spacer top="8" bottom="8" />
                <AboutMe />
                <Spacer top="8" bottom="8" />
                <BlogSlider />
                <Spacer top="8" bottom="8" />
            </Wrapper>
        </Layout>
    )
}

export default IndexPage
